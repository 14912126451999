import React, {createContext, useState} from 'react';
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";
import AuthService from "../services/AuthService";
import UserService from "../services/UserService";
import {
    ACCESS_TOKEN,
    BRANCH,
    BRANCH_CONFIG,
    GLOBAL_CONFIG,
    LANGUAGE,
    POS_ORDER_CART,
    POS_ORDER_CART_INVOICE,
    POS_ORDER_HOLD_LIST,
    POS_ORDER_INFO,
    PROFILE,
    REFRESH_TOKEN,
    RESTAURANT,
    USER_TYPE
} from "../helpers/Constant";
import GlobalConfigService from "../services/GlobalConfigService";
import BranchConfigService from "../services/BranchConfigService";
import LocalStorageUtils from "../helpers/LocalStorageUtils";
import {disconnectWebSocket} from "../web_socket/ConnectToWebSocket";

export const AuthContext = createContext("AuthContext");

const auth = LocalStorageUtils.getItem(ACCESS_TOKEN);

const profileData = LocalStorageUtils.getItem(PROFILE);

const _selectedRestaurant = LocalStorageUtils.getItem(RESTAURANT);

const _selectedBranch = LocalStorageUtils.getItem(BRANCH);

let _branchConfig = LocalStorageUtils.getItem(BRANCH_CONFIG);
_branchConfig = _branchConfig ? JSON.parse(_branchConfig) : null;

let _userType = LocalStorageUtils.getItem(USER_TYPE);
_userType = _userType ? _userType : "BRANCH";

const AuthContextProvider = ({children}) => {

    const [isLogin, setIsLogin] = useState(!!auth);
    const [profileLoading, setProfileLoading] = useState(false);

    const [profile, setProfile] = useState(profileData ? JSON.parse(profileData) : null);

    const [loading, setLoading] = useState(false);

    const [selectedRestaurant, setSelectedRestaurant] = useState(_selectedRestaurant ? JSON.parse(_selectedRestaurant) : null);
    const [selectedBranch, setSelectedBranch] = useState(_selectedRestaurant ? JSON.parse(_selectedBranch) : null);

    const [branchConfig, setBranchConfig] = useState(_branchConfig);

    const [userType, setUserType] = useState(_userType);

    const login = async credential => {

        try {

            setLoading(true);

            const response = await AuthService.login(credential);

            LocalStorageUtils.setItem(ACCESS_TOKEN, response.data.token.access);
            LocalStorageUtils.setItem(REFRESH_TOKEN, response.data.token.refresh);

            setIsLogin(true);
            setLoading(false);

        } catch (error) {

            setLoading(false);
            setIsLogin(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }

    }

    const ownerLogin = async credential => {

        try {

            setLoading(true);

            const response = await AuthService.ownerLogin(credential);

            LocalStorageUtils.setItem(ACCESS_TOKEN, response.data.token.access);
            LocalStorageUtils.setItem(REFRESH_TOKEN, response.data.token.refresh);

            setIsLogin(true);
            setLoading(false);

        } catch (error) {

            setLoading(false);
            setIsLogin(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }

    }

    const getUserProfile = async () => {

        try {

            setProfileLoading(true);

            let branchConfigRes = null;

            const userCall = UserService.getUserProfile();
            const globalCall = GlobalConfigService.getPublicGlobalConfig();

            let calls = [userCall, globalCall];

            const [profileRes, globalConfigRes] = await Promise.all(calls);

            LocalStorageUtils.setItem(RESTAURANT, JSON.stringify(profileRes.data.restaurant));
            LocalStorageUtils.setItem(BRANCH, JSON.stringify(profileRes.data.branch));

            LocalStorageUtils.setItem(GLOBAL_CONFIG, JSON.stringify(globalConfigRes.data));

            if (userType === "BRANCH") {
                branchConfigRes = await BranchConfigService.getPublicBranchConfig();
                LocalStorageUtils.setItem(BRANCH_CONFIG, JSON.stringify(branchConfigRes.data));
                setBranchConfig(branchConfigRes.data);
            }

            setProfile({...profileRes.data});
            setSelectedRestaurant(profileRes.data.restaurant);
            setSelectedBranch(profileRes.data.branch);

            setProfileLoading(false);

            return {
                profile: profileRes.data,
                globalConfig: globalConfigRes.data,
                branchConfig: branchConfigRes?.data
            };

        } catch (error) {

            setProfileLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

            return null;

        }
    }

    const changeUserType = userType => {
        setUserType(userType);
        LocalStorageUtils.setItem(USER_TYPE, userType);
    }

    const logout = () => {
        disconnectWebSocket();
        setIsLogin(false);
        clearLocalStorage();
    }

    const clearLocalStorage = () => {
        LocalStorageUtils.removeItem(ACCESS_TOKEN);
        LocalStorageUtils.removeItem(REFRESH_TOKEN);
        LocalStorageUtils.removeItem(PROFILE);
        LocalStorageUtils.removeItem(RESTAURANT);
        LocalStorageUtils.removeItem(BRANCH);
        LocalStorageUtils.removeItem(LANGUAGE);
        LocalStorageUtils.removeItem(GLOBAL_CONFIG);
        LocalStorageUtils.removeItem(BRANCH_CONFIG);
        LocalStorageUtils.removeItem(POS_ORDER_CART);
        LocalStorageUtils.removeItem(POS_ORDER_CART_INVOICE);
        LocalStorageUtils.removeItem(POS_ORDER_INFO);
        LocalStorageUtils.removeItem(POS_ORDER_HOLD_LIST);
        LocalStorageUtils.removeItem(USER_TYPE);
    }

    return (
        <AuthContext.Provider
            value={{
                isLogin,
                profile,
                loading,
                profileLoading,
                permissions: profile ? profile.permissions : [],
                selectedRestaurant,
                selectedBranch,
                setProfile,
                branchConfig,
                login,
                ownerLogin,
                logout,
                getUserProfile,
                userType,
                changeUserType,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;
