import * as URL from "../configs/APIUrl";
import privateAPI from "../rest_handlers/privateAPI";
import {getBranchId} from "../helpers/Utils";

export default class StockTransactionService {

    static createStockTransaction = data => privateAPI.post(
        `${URL.CREATE_STOCK_TRANSACTION_URL}/${getBranchId()}`, data
    );

    static stockTransactionOut = data => privateAPI.post(
        `${URL.STOCK_TRANSACTION_OUT_URL}/${getBranchId()}`, data
    );

    static updateStockTransactionOut = (id, data) => privateAPI.put(
        `${URL.UPDATE_STOCK_TRANSACTION_OUT_URL}/${id}/${getBranchId()}`, data
    );

    static getAllStockTransaction = params => privateAPI.get(
        `${URL.GET_ALL_STOCK_TRANSACTION_URL}/${getBranchId()}`, {
            params,
            paramsSerializer: {
                indexes: null // by default: false
            }
        }
    );

    static getStockTransactionById = id => privateAPI.get(`${URL.GET_STOCK_TRANSACTION_BY_ID_URL}/${id}/${getBranchId()}`);

    static updateStockTransactionIn = (id, data) => privateAPI.put(
        `${URL.UPDATE_STOCK_TRANSACTION_IN_URL}/${id}/${getBranchId()}`,
        data
    );

    static getStockTransactionsByIngredientId = params => privateAPI.get(
        `${URL.GET_STOCK_TRANSACTION_BY_INGREDIENT_ID}/${getBranchId()}`,
        {params}
    );

    static adjustMultipleStockTransaction = data => privateAPI.put(
        `${URL.ADJUST_MULTIPLE_STOCK_TRANSACTION_PAYMENT}/${getBranchId()}`, data
    );

}
