import React, {createContext, useState} from 'react';
import {getErrorMessage, getPercentDiscountAmount, percentValueCalculation} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";
import BranchOrderService from "../services/order/BranchOrderService";
import {
    BRANCH_CONFIG,
    QR_CODE_DATA,
    QR_CODE_LAST_ORDER_ID,
    QR_CODE_ORDER_CART,
    QR_CODE_ORDER_INVOICE
} from "../helpers/Constant";
import LocalStorageUtils from "../helpers/LocalStorageUtils";

export const BranchQrCodeOrderContext = createContext("BranchQrCodeOrderContext");

const BranchQrCodeOrderContextProvider = ({children}) => {

    const initInvoice = {
        totalProductPrice: 0,
        totalAddOnPrice: 0,
        subTotal: 0,
        taxValue: 0,
        grandTotal: 0
    }

    let _qrCodeData = LocalStorageUtils.getItemForBranch(QR_CODE_DATA);
    _qrCodeData = _qrCodeData ? JSON.parse(_qrCodeData) : null;

    const uuid = LocalStorageUtils.getItemForBranch(QR_CODE_LAST_ORDER_ID);

    let localStorageInvoice = LocalStorageUtils.getItemForBranch(QR_CODE_ORDER_INVOICE);
    localStorageInvoice = localStorageInvoice ? JSON.parse(localStorageInvoice) : initInvoice;

    let branchConfig = LocalStorageUtils.getItem(BRANCH_CONFIG);
    branchConfig = branchConfig ? JSON.parse(branchConfig) : null;

    const [cartMap, setCartMap] = useState(null);

    const [invoice, setInvoice] = useState(localStorageInvoice);
    const [createLoading, setCreateLoading] = useState(false);

    const [qrCodeData, setQrCodeData] = useState(_qrCodeData);
    const [lastOrderId, setLastOrderId] = useState(uuid ? uuid : null);

    const addToCart = data => {

        const _cartMap = {
            ...cartMap,
            [data.id]: {
                branchProduct: data,
                quantity: 1,
                cartAddOnDetailsList: [],
            }
        }

        LocalStorageUtils.setItemForBranch(QR_CODE_ORDER_CART, JSON.stringify(_cartMap));
        setCartMap(_cartMap);

        getInvoiceCalculation(_cartMap);

    }

    const cartIncrementDecrement = (id, operation) => {

        let _cartMap = {...cartMap};
        const cartMapDetails = _cartMap[id];

        if (operation === -1 && cartMapDetails.quantity === 1) {
            removeCartItem(id);
            return;
        }

        _cartMap[id] = {
            ...cartMapDetails,
            quantity: cartMapDetails.quantity + operation
        }

        setCartMap(_cartMap);
        LocalStorageUtils.setItemForBranch(QR_CODE_ORDER_CART, JSON.stringify(_cartMap));

        getInvoiceCalculation(_cartMap);

    }

    const addAddOn = (branchProductId, branchAddOn) => {

        const _cartMap = {...cartMap};
        const cartMapDetails = _cartMap[branchProductId];

        _cartMap[branchProductId] = {
            ...cartMapDetails,
            cartAddOnDetailsList: [
                ...cartMapDetails.cartAddOnDetailsList,
                {
                    branchAddOn,
                    quantity: 1
                }
            ]
        }

        setCartMap(_cartMap);
        LocalStorageUtils.setItemForBranch(QR_CODE_ORDER_CART, JSON.stringify(_cartMap));

        getInvoiceCalculation(_cartMap);

    }

    const removeCartItem = (id) => {

        delete cartMap[id];

        const _cartMap = {...cartMap}

        setCartMap(_cartMap);
        LocalStorageUtils.setItemForBranch(QR_CODE_ORDER_CART, JSON.stringify(_cartMap));

        if (Object.keys(cartMap)?.length < 1) {
            setInvoice(initInvoice);
            return;
        }

        getInvoiceCalculation(cartMap);

    }

    const removeAddOn = (branchProductId, branchAddOnId) => {

        const _cartMap = {...cartMap};
        const cartMapDetails = _cartMap[branchProductId];

        _cartMap[branchProductId] = {
            ...cartMapDetails,
            cartAddOnDetailsList: cartMapDetails.cartAddOnDetailsList
                .filter(cartAddOnDetails => cartAddOnDetails.branchAddOn.id !== branchAddOnId)
        }

        setCartMap(_cartMap);
        LocalStorageUtils.setItemForBranch(QR_CODE_ORDER_CART, JSON.stringify(_cartMap));

        getInvoiceCalculation(_cartMap);

    }

    const incrementDecrementAddOn = (branchProductId, branchAddOnId, operation) => {

        const _cartMap = {...cartMap};
        const cartMapDetails = _cartMap[branchProductId];

        _cartMap[branchProductId] = {
            ...cartMapDetails,
            cartAddOnDetailsList: cartMapDetails.cartAddOnDetailsList.map(cartAddOnDetails => {

                if (operation === -1 && cartAddOnDetails.quantity === 1 && cartAddOnDetails.branchAddOn.id === branchAddOnId) {
                    removeAddOn(branchProductId, branchAddOnId);
                    return undefined;
                }

                if (cartAddOnDetails.branchAddOn.id === branchAddOnId) {
                    return {
                        ...cartAddOnDetails,
                        quantity: cartAddOnDetails.quantity + operation
                    }
                }
                return cartAddOnDetails;
            }).filter(a => a !== undefined)
        }

        setCartMap(_cartMap);
        LocalStorageUtils.setItemForBranch(QR_CODE_ORDER_CART, JSON.stringify(_cartMap));

        getInvoiceCalculation(_cartMap);

    }

    const getInvoiceCalculation = cartMap => {

        let _totalProductPrice = 0;
        let _totalAddOnPrice = 0;
        let _subTotal = 0;

        for (let key in cartMap) {

            _totalProductPrice += getPercentDiscountAmount(cartMap[key].branchProduct.price, cartMap[key].branchProduct.discountPercent) * cartMap[key].quantity;

            for (let i = 0; i < cartMap[key].cartAddOnDetailsList.length; i++) {
                _totalAddOnPrice += cartMap[key].cartAddOnDetailsList[i].quantity * cartMap[key].cartAddOnDetailsList[i].branchAddOn.price;
            }

        }

        _subTotal = _totalProductPrice + _totalAddOnPrice;

        let taxValue = percentValueCalculation(_subTotal, branchConfig?.taxPercentage);


        const _invoice = {
            ...invoice,
            totalProductPrice: _totalProductPrice,
            totalAddOnPrice: _totalAddOnPrice,
            subTotal: _subTotal,
            taxValue,
            grandTotal: _subTotal + taxValue
        }

        setInvoice(_invoice);
        LocalStorageUtils.setItemForBranch(QR_CODE_ORDER_INVOICE, JSON.stringify(_invoice));

    }

    const createQrCodeOrder = async data => {
        try {

            setCreateLoading(true);

            const cartDetailsList = convertCartDetailsMap();
            const orderData = {
                cartDetailsList,
                ...data,
                orderType: qrCodeData?.orderType,
                tableNumber: qrCodeData?.branchTableId,
                payments: [{type: "CASH", amount: 0}]
            }

            setCartMap(null);
            setInvoice(initInvoice);

            const res = await BranchOrderService.createBranchQrCodeOrder(qrCodeData?.branchId, orderData);
            setLastOrderId(res.data.uuid);

            LocalStorageUtils.setItemForBranch(QR_CODE_LAST_ORDER_ID, res.data.uuid);
            LocalStorageUtils.removeItemForBranch(QR_CODE_ORDER_CART);
            LocalStorageUtils.setItemForBranch(QR_CODE_ORDER_INVOICE, JSON.stringify(initInvoice));

            Toast("success", "Order Placed", "Your order has been placed successfully");

            setCreateLoading(false);

        } catch (error) {

            setCreateLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const convertCartDetailsMap = () => {

        let cartDetailsList = [];

        for (let key in cartMap) {
            cartDetailsList.push(cartMap[key]);
        }

        return cartDetailsList;
    }

    const qrCodeDataInit = data => {
        setQrCodeData(data);
        LocalStorageUtils.setItemForBranch(QR_CODE_DATA, JSON.stringify(data));
        // LocalStorageUtils.setItem(QR_CODE_DATA, JSON.stringify(data));
    }

    return (
        <BranchQrCodeOrderContext.Provider
            value={{
                addToCart,
                cartMap,
                setCartMap,
                invoice,
                cartIncrementDecrement,
                incrementDecrementAddOn,
                removeCartItem,
                addAddOn,
                removeAddOn,
                setInvoice,
                createQrCodeOrder,
                createLoading,
                qrCodeDataInit,
                qrCodeData,
                lastOrderId,
                branchConfig,
            }}
        >
            {children}
        </BranchQrCodeOrderContext.Provider>
    );
}

export default BranchQrCodeOrderContextProvider;
