import * as URL from "../../configs/APIUrl";
import privateAPI from "../../rest_handlers/privateAPI";
import {getBranchId} from "../../helpers/Utils";

export default class OrderService {

    static getAllOrder = params => {

        return privateAPI.get(
            `${URL.GET_ALL_ORDER_URL}`, {
                params,
                paramsSerializer: {
                    indexes: null // by default: false
                }
            }
        );
    }

    static getOrderById = orderId => privateAPI.get(
        `${URL.GET_ORDER_BY_ID_URL}/${orderId}`
    );

    static getOrderStateList = orderId => privateAPI.get(
        `${URL.GET_ORDER_STATE_URL}/${orderId}`
    );


    static getOrderHistory = orderId => privateAPI.get(
        `${URL.GET_ORDER_HISTORY_URL}/${orderId}`
    );

    static updateOrderState = (orderId, params) => privateAPI.put(
        `${URL.UPDATE_ORDER_STATE_URL}/${orderId}/${getBranchId()}`, null, {params}
    );

}
