import React, {createContext, useState} from 'react';
import {getErrorMessage} from "../helpers/Utils";
import CustomerService from "../services/CustomerService";
import {Toast} from "../components/common/Toast";


export const CustomerContext = createContext("CustomerContext");

const CustomerContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0);
    const [customerList, setCustomerList] = useState([]);

    const getAllCustomer = async (params) => {
        try {

            setLoading(true);

            const res = await CustomerService.getAllCustomer(params);
            setCustomerList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    const getAllCustomerForBranch = async (params) => {
        try {

            setLoading(true);

            const res = await CustomerService.getAllCustomerForBranch(params);
            setCustomerList(res.data.content);
            setTotalElements(res.data.totalElements);

            setLoading(false);

        } catch (error) {

            setLoading(false);

            const message = getErrorMessage(error);
            Toast("error", "Error", message);

        }
    }

    return (
        <CustomerContext.Provider
            value={{
                loading,
                totalElements,
                customerList,
                getAllCustomer,
                getAllCustomerForBranch,
            }}
        >
            {children}
        </CustomerContext.Provider>
    );
}

export default CustomerContextProvider;
